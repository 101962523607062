@import "variables";

html {
	height: 100%;
}
section {
	margin-top: 35px;
	font-size: 14px;
}
section h2 {
	margin-top: 10px;
	margin-bottom: 30px;
}
h1,h2,h3,h4,h5,h6 {
	font-family: 'Noto Sans JP', sans-serif;
}
footer {
	margin-top: 40px;
	padding: 20px 0 10px 0;
}
hr {
	width: 80%;
	margin: 60px auto 60px auto;
}
body#body {
	font-family: 'Fira Sans', sans-serif;
	font-weight: 300;
}

#body {
	/* Main navbar */
	#main-navbar {
		ul.navbar-nav {
			margin: 0 0 0 auto;
			align-items: center;
		}
		nav.navbar-light ul.navbar-nav a.nav-link {
			color: black;
		}
		nav.navbar-light ul.navbar-nav a.nav-link:hover {
			color: #2ea0f1;
		}
		nav.navbar ul.navbar-nav li.nav-item {
			padding-left: 20px;
		}
	}

	@media (min-width:992px) {
		nav.navbar ul.navbar-nav li.nav-item {
			padding-left:40px;
		}
	}
	@media (min-width: 576px) {
		.project-img {
			max-height: 120px;
		}
	}

	table.tight-width {
		width: auto !important;
	}
	table img {
		object-fit: scale-down;
		max-height: 80px;
		width: 100px;
	}
	table td {
		vertical-align: middle!important;
	}
	table td:first-child {
		text-align: center;
	}

	li.list-group-item {
		padding: 5px 0 5px 0;
	}

	.small {
		font-size: 95%;
		font-weight: 300;
	}
	.smaller {
		font-size: 90%;
		font-weight: 300;
	}
	.smallest {
		font-size: 80%;
		font-weight: 300;
	}

	.project-tag-website {
		background-color: blue;
		color: white;
	}
	.project-tag-bio {
		background-color: green;
		color: white;
	}
	.project-tag-security {
		background-color: red;
		color: white;
	}

	#profile-img {
		object-fit: cover;
		border-radius: 50%;
		height: 200px;
		width: 200px;
	}

	/* Post */
	.markdown-body {
		background-color: white;

		h2 {
			margin-top: 36px;
		}
		blockquote {
			font-style: italic;
			border-left-color: red;
			margin-left: 20px;
		}
		p > img {
			margin-left: auto;
			margin-right: auto;
			display: block;
		}
	}
}

// Settled on moving the import of syntax highlighting to the bottom of the CSS
// ... Otherwise it really bloats up the top of the CSS file and makes it difficult to find the start
@import "message";
@import "syntax";
@import "pagination";
@import "github-markdown";
@import "github";